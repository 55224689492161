import { store } from '../store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import ApiClient from '../util/apiClient'
export const initialState = {
	validRunner: false,
	postFacilityAuditLoginStatus: null,
	loginErrorStatusCode: null
}

export const postFacilityAuditLogin = createAsyncThunk(
	'audit/postFacilityAuditLogin',
	async (data) => {
		const apiClient = new ApiClient()
		const { body, id } = data
		return await apiClient
			.post(`/facility/${id}/audit/login`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const auditSlice = createSlice({
	name: 'audit',
	initialState,
	reducers: {
		setValidRunner: (state, action) => {
			state.validRunner = action.payload
		},
		resetPostFacilityAuditLoginStatus: (state) => {
			state.postFacilityAuditLoginStatus = null
		},
		resetLogInErrorStatusCode: (state) => {
			state.loginErrorStatusCode = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(postFacilityAuditLogin.pending, (state) => {
				state.postFacilityAuditLoginStatus = 'PENDING'
			})
			.addCase(postFacilityAuditLogin.fulfilled, (state) => {
				state.postFacilityAuditLoginStatus = 'FULFILLED'
				state.validRunner = true
			})
			.addCase(postFacilityAuditLogin.rejected, (state, action) => {
				state.postFacilityAuditLoginStatus = 'REJECTED'
				console.log(action.error.message)
				state.loginErrorStatusCode = action.error.message.includes('401')
					? 401
					: null
			})
	}
})

const { actions, reducer } = auditSlice
export const {
	setValidRunner,
	resetPostFacilityAuditLoginStatus,
	resetLogInErrorStatusCode
} = actions
export default reducer
