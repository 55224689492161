import { store } from '../store'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { CONTRACT } from '../util/constants'

import ApiClient from '../util/apiClient'
import numberUtil from '../util/number'
import objectUtil from '../util/object'

export const initialState = {
	putContactError: {},
	getMemberContractsStatus: null,
	getContractStatus: null,
	getPersonStatus: null,
	putContractStatus: null,
	putContractEntryAccessStatus: null,
	getInvoicesByContractIdStatus: null,
	putInvoiceStatus: null,
	postChargeInvoiceStatus: null,
	getPromotionsStatus: null,
	postPromotionStatus: null,
	putPromotionStatus: null,
	getDiscountsStatus: null,
	postDiscountStatus: null,
	putDiscountStatus: null,
	postUnlockFacilityStatus: null,
	postTerminateContractStatus: null,
	postApproveContractStatus: null,
	getCountriesStatus: null,
	getMetrosStatus: null,
	postWebsitePhotosStatus: null,
	postMemberDashboardPhotosStatus: null,
	getUpcomingLedgerItemsStatus: null,
	getCompletedInvoicesStatus: null,
	putLedgerItemStatus: null,
	postCancelInvoiceStatus: null,
	postRefundInvoiceStatus: null,
	postContractBalanceStatus: null,
	postMetroStatus: null,
	postCityStatus: null,
	postCalculateNextPaymentStatus: null,
	postUnitsStatus: null,
	getUnitsStatus: null,
	upcomingMemberTransactions: [],
	completedMemberTransactions: [],
	promotions: [],
	discounts: [],
	memberContracts: [],
	pendingMemberContracts: [],
	activePerson: {},
	activePersonNextPayment: {},
	activeContract: {},
	countries: [],
	metros: [],
	units: [],
	activeInvoiceList: null,
	getCaller: null,
	tags: [],
	getTagsStatus: null,
	postTagStatus: null,
	putTagStatus: null,
	getPersonByPhoneNbrStatus: null,
	getPersonByPhoneErrorCode: null,
	postContractSecondaryAccessStatus: null,
	secondaryAccess: [],
	getSecondaryAccessStatus: null,
	personByPhone: {},
	putContractSecondaryAccessStatus: null,
	getMembershipContractStatus: null,
	membership: {},
	postMembershipStatus: null,
	insurance: {},
	getInsuranceContractStatus: null,
	insuranceFacility: [],
	getInsuranceFacilityStatus: null,
	postCancelInsuranceStatus: null,
	postInsuranceStatus: null,
	contractOrganizationKits: [],
	getContractOrganizationKitsStatus: null,
	facilityOrganizationKits: [],
	getFacilityOrganizationKitsStatus: null,
	unitTags: [],
	getUnitTagsStatus: null,
	postUnitTagStatus: null,
	postUnitNoteStatus: null,
	facilityUnits: [],
	facilityPromotions: [],
	getFacilityUnitsStatus: null,
	getFacilityPromotionsStatus: null,
	postGenerateBookingLinkStatus: null,
	bookingLink: null,
	audit: [],
	getFacilityAuditStatus: null,
	facilityRunners: [],
	getFacilityRunnersStatus: null,
	putFacilityRunnerStatus: null,
	postFacilityRunnerStatus: null
}

export const getPromotions = createAsyncThunk(
	'adminDashboard/getPromotions',
	async (orgId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/promotion/org/${orgId}`, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postPromotion = createAsyncThunk(
	'adminDashboard/post/promotion',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`promotion`, null, objectUtil.removeEmptyKeys(body))
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putPromotion = createAsyncThunk(
	'adminDashboard/put/promotion',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put(`promotion`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getDiscounts = createAsyncThunk(
	'adminDashboard/getDiscounts',
	async (orgId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/discount/org/${orgId}`, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postDiscount = createAsyncThunk(
	'adminDashboard/post/discount',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`discount`, null, objectUtil.removeEmptyKeys(body))
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putDiscount = createAsyncThunk(
	'adminDashboard/put/discount',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put(`discount`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getMemberContracts = createAsyncThunk(
	'adminDashboard/getMemberContracts',
	async (data) => {
		const { id, status } = data
		let queryUrl = `/contract?org_id=${id}`
		status &&
			status.forEach((code) => {
				queryUrl += `&status=${code}`
			})
		const apiClient = new ApiClient()
		return await apiClient
			.get(queryUrl, null)
			.then((resp) => ({ data: resp, status: status }))
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postApproveContract = createAsyncThunk(
	'adminDashboard/contract/approve',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`contract/approval`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getPerson = createAsyncThunk(
	'adminDashboard/get/person',
	async ({ personId, caller }) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/person/id/${personId}`, null, null)
			.then((resp) => {
				return { data: resp, caller }
			})
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getContract = createAsyncThunk(
	'adminDashboard/get/contract/id',
	async (contractId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`contract/id/${contractId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putContract = createAsyncThunk(
	'adminDashboard/put/contract',
	async (body, { rejectWithValue }) => {
		// Remove empty, null or undefined values
		body = objectUtil.removeEmptyKeys(body)
		const apiClient = new ApiClient()
		return await apiClient
			.put(`contract/v3`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(JSON.stringify(rejectWithValue(err).payload))
			})
	}
)

export const putContractEntryAccess = createAsyncThunk(
	'adminDashboard/put/contract/entry-access',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put(`/contract/entry-access`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getInvoicesByContractId = createAsyncThunk(
	'adminDashboard/get/invoice/contract',
	async (contractId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`invoice/contract/${contractId}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putInvoice = createAsyncThunk(
	'adminDashboard/put/invoice/id',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put(`invoice`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postChargeInvoice = createAsyncThunk(
	'adminDashboard/post/chargeInvoice',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(
				`payment/chargeInvoice`,
				{ 'x-idempotence-key': numberUtil.getUUID() },
				body
			)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postUnlockFacilityV5 = createAsyncThunk(
	'adminDashboard/postUnlockFacilityV5',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/facility/v5/unlock`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postTerminateContract = createAsyncThunk(
	'adminDashboard/contract/terminate',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/contract/terminate`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getCountries = createAsyncThunk(
	'adminDashboard/get/countries',
	async (params) => {
		const { fields } = params
		let queryUrl = '/country'
		if (fields) {
			queryUrl += `?fields=${fields[0]}`
			for (let i = 1; i < fields.length; i++) {
				queryUrl += `,${fields[i]}`
			}
		}
		const apiClient = new ApiClient()
		return await apiClient
			.get(queryUrl, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getMetros = createAsyncThunk(
	'adminDashboard/get/metros',
	async () => {
		const apiClient = new ApiClient()
		return await apiClient
			.get('metro', null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postWebsitePhotos = createAsyncThunk(
	'adminDashboard/post/websitePhotos',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/facility/photos/${body?.facilityId}`, null, body?.formData)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)
export const postMemberDashboardPhotos = createAsyncThunk(
	'adminDashboard/post/memberDashboardPhotos',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/facility/photos/member/${body?.facilityId}`, null, body?.formData)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getUpcomingLedgerItems = createAsyncThunk(
	'adminDashboard/getUpcomingLedgerItems',
	async (contractId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/contract/${contractId}/ledger_items/upcoming`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const putLedgerItem = createAsyncThunk(
	'adminDashboard/putLedgerItem',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put(`ledger_item`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const getCompletedInvoices = createAsyncThunk(
	'adminDashboard/getCompletedInvoices',
	async (contractId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/contract/${contractId}/invoice/completed`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postCancelInvoice = createAsyncThunk(
	'adminDashboard/postCancelInvoice',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/invoice/${id}/cancel`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postRefundInvoice = createAsyncThunk(
	'adminDashboard/postRefundInvoice',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/invoice/${id}/refund`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postContractBalance = createAsyncThunk(
	'adminDashboard/postContractBalance',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/contract/balance`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postMetro = createAsyncThunk(
	'adminDashboard/postMetro',
	async (metro) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/metro', null, metro)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postCity = createAsyncThunk(
	'adminDashboard/postCity',
	async (city) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/city', null, city)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err)
			})
	}
)

export const postCalculateNextPayment = createAsyncThunk(
	'adminDashboard/postCalculateNextPayment',
	async (person_id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/payment/calculateNextPayment`, null, { person_id })
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const postUnits = createAsyncThunk(
	'adminDashboard/postUnits',
	async (data) => {
		const { facilityId, file } = data
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/facility/${facilityId}/units`, null, file)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getUnits = createAsyncThunk(
	'adminDashboard/getUnits',
	async (facility_id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/facility/${facility_id}/units`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getTags = createAsyncThunk('adminDashboard/getTags', async () => {
	const apiClient = new ApiClient()
	return await apiClient
		.get('/tag?fields=facilities', null, null)
		.then((resp) => resp)
		.catch((err) => {
			throw new Error(err.message)
		})
})

export const postTag = createAsyncThunk(
	'adminDashboard/postTag',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/tag', null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const putTag = createAsyncThunk(
	'adminDashboard/putTag',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put('/tag', null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getSecondaryAccess = createAsyncThunk(
	'adminDashboard/getSecondaryAccess',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/contract/${id}/secondary`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const postContractSecondaryAccess = createAsyncThunk(
	'adminDashboard/postContractSecondaryAccess',
	async (data) => {
		const apiClient = new ApiClient()
		const { id, payload } = data
		return await apiClient
			.post(`/contract/${id}/secondary`, null, payload)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const putContractSecondaryAccess = createAsyncThunk(
	'adminDashboard/putContractSecondaryAccess',
	async (data) => {
		const apiClient = new ApiClient()
		const { id, payload } = data
		return await apiClient
			.put(`/contract/${id}/secondary`, null, payload)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getPersonByPhoneNbr = createAsyncThunk(
	'root/get/personByPhoneNbr',
	async (phoneNumber, { rejectWithValue }) => {
		const apiClient = new ApiClient()
		return await apiClient
			.getv3(`/person/phone/${phoneNumber}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(JSON.stringify(rejectWithValue(err).payload))
			})
	}
)

export const getMembershipContract = createAsyncThunk(
	'adminDashboard/getMembershipContract',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/membership/contract/${id}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const postMembership = createAsyncThunk(
	'adminDashboard/postMembership',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/membership', null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getInsuranceContract = createAsyncThunk(
	'adminDashboard/getInsuranceContract',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/insurance/contract/${id}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getInsuranceFacility = createAsyncThunk(
	'adminDashboard/getInsuranceFacility',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/insurance/facility/${id}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const postCancelInsurance = createAsyncThunk(
	'adminDashboard/postCancelInsurance',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/insurance/terminate`, null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const postInsurance = createAsyncThunk(
	'adminDashboard/postInsurance',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/insurance', null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getContractOrganizationKits = createAsyncThunk(
	'adminDashboard/getContractOrganizationKits',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/organization_kit/contract/${id}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getFacilityOrganizationKits = createAsyncThunk(
	'adminDashboard/getFacilityOrganizationKits',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/organization_kit/facility/${id}`, null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getUnitTags = createAsyncThunk(
	'adminDashboard/getUnitTags',
	async () => {
		const apiClient = new ApiClient()
		return await apiClient
			.get('/unit_tag', null, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const postUnitTag = createAsyncThunk(
	'adminDashboard/postUnitTag',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/unit_tag', null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const postUnitNote = createAsyncThunk(
	'adminDashboard/postUnitNote',
	async ({ id, data }) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post(`/unit/${id}/note`, null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getFacilityUnits = createAsyncThunk(
	'adminDashboard/getFacilityUnits',
	async ({ id, fields }) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(
				`/facility/v3/${id}/units/available${fields ? fields : ''}`,
				null,
				null
			)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getFacilityPromotions = createAsyncThunk(
	'adminDashboard/getFacilityPromotions',
	async (id) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/promotion/facility/${id}`, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const postGenerateBookingLink = createAsyncThunk(
	'adminDashboard/postGenerateBookingLink',
	async (data) => {
		const apiClient = new ApiClient()
		return await apiClient
			.post('/reservation/booking_link', null, data)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getFacilityAudit = createAsyncThunk(
	'adminDashboard/getFacilityAudit',
	async (facilityId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/facility/${facilityId}/audit`, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const getFacilityRunners = createAsyncThunk(
	'adminDashboard/getFacilityRunners',
	async (facilityId) => {
		const apiClient = new ApiClient()
		return await apiClient
			.get(`/facility/${facilityId}/runner`, null)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const putFacilityRunner = createAsyncThunk(
	'adminDashboard/putFacilityRunner',
	async (body) => {
		const apiClient = new ApiClient()
		return await apiClient
			.put(`/facility/runner`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const postFacilityRunner = createAsyncThunk(
	'adminDashboard/postFacilityRunner',
	async (data) => {
		const apiClient = new ApiClient()
		const { facilityId, body } = data
		return await apiClient
			.post(`/facility/${facilityId}/runner`, null, body)
			.then((resp) => resp)
			.catch((err) => {
				throw new Error(err.message)
			})
	}
)

export const adminDashboardSlice = createSlice({
	name: 'adminDashboard',
	initialState,
	reducers: {
		resetGetPromotionsStatus: (state) => {
			state.getPromotionsStatus = null
		},
		resetPostPromotionStatus: (state) => {
			state.postPromotionStatus = null
		},
		resetPutPromotionStatus: (state) => {
			state.putPromotionStatus = null
		},
		resetGetDiscountsStatus: (state) => {
			state.getDiscountsStatus = null
		},
		resetPostDiscountStatus: (state) => {
			state.postDiscountStatus = null
		},
		resetPutDiscountStatus: (state) => {
			state.putDiscountStatus = null
		},
		resetGetMemberContractsStatus: (state) => {
			state.getMemberContractsStatus = null
		},
		resetGetContractStatus: (state) => {
			state.getContractStatus = null
		},
		resetPutContractStatus: (state) => {
			state.putContractStatus = null
		},
		resetPostApproveContractStatus: (state) => {
			state.postApproveContractStatus = null
		},
		resetGetPersonStatus: (state) => {
			state.getPersonStatus = null
		},
		resetPutContractEntryAccessStatus: (state) => {
			state.putContractEntryAccessStatus = null
		},
		resetActivePerson: (state) => {
			state.activePerson = {}
		},
		resetActiveContract: (state) => {
			state.activeContract = {}
		},
		setActiveContract: (state, { payload }) => {
			state.activeContract = payload
		},
		resetActiveInvoiceList: (state) => {
			state.activeInvoiceList = null
		},
		resetGetInvoicesByContractIdStatus: (state) => {
			state.getInvoicesByContractIdStatus = null
		},
		resetPutInvoiceStatus: (state) => {
			state.putInvoiceStatus = null
		},
		resetPostChargeInvoiceStatus: (state) => {
			state.postChargeInvoiceStatus = null
		},
		resetPostUnlockFacilityStatus: (state) => {
			state.postUnlockFacilityStatus = null
		},
		resetPostTerminateContractStatus: (state) => {
			state.postTerminateContractStatus = null
		},
		resetGetCountriesStatus: (state) => {
			state.getCountriesStatus = null
		},
		resetPostWebsitePhotosStatus: (state) => {
			state.postWebsitePhotosStatus = null
		},
		resetPostMemberDashboardPhotosStatus: (state) => {
			state.postMemberDashboardPhotosStatus = null
		},
		resetGetUpcomingLedgerItemsStatus: (state) => {
			state.getUpcomingLedgerItemsStatus = null
		},
		resetPutLedgerItemStatus: (state) => {
			state.putLedgerItemStatus = null
		},
		resetGetCompletedInvoicesStatus: (state) => {
			state.getCompletedInvoicesStatus = null
		},
		resetPostCancelInvoiceStatus: (state) => {
			state.postCancelInvoiceStatus = null
		},
		resetPostRefundInvoiceStatus: (state) => {
			state.postRefundInvoiceStatus = null
		},
		resetPostContractBalanceStatus: (state) => {
			state.postContractBalanceStatus = null
		},
		resetGetMetrosStatus: (state) => {
			state.getMetrosStatus = null
		},
		resetPostMetroStatus: (state) => {
			state.postMetroStatus = null
		},
		resetPostCityStatus: (state) => {
			state.postCityStatus = null
		},
		resetPostCalculateNextPaymentStatus: (state) => {
			state.postCalculateNextPaymentStatus = null
		},
		resetPostUnitsStatus: (state) => {
			state.postUnitsStatus = null
		},
		resetGetUnitsStatus: (state) => {
			state.getUnitsStatus = null
		},
		resetGetTagsStatus: (state) => {
			state.getTagsStatus = null
		},
		resetPostTagStatus: (state) => {
			state.postTagStatus = null
		},
		resetPutTagStatus: (state) => {
			state.putTagStatus = null
		},
		resetGetPersonByPhoneNbrStatus: (state) => {
			state.getPersonByPhoneNbrStatus = null
		},
		resetGetPersonByPhoneErrorCode: (state) => {
			state.getPersonByPhoneErrorCode = null
		},
		resetGetSecondaryAccessStatus: (state) => {
			state.getSecondaryAccessStatus = null
		},
		resetpostContractSecondaryAccessStatus: (state) => {
			state.postContractSecondaryAccessStatus = null
		},
		resetPutContractSecondaryAccessStatus: (state) => {
			state.putContractSecondaryAccessStatus = null
		},
		resetGetMembershipContractStatus: (state) => {
			state.getMembershipContractStatus = null
		},
		resetPostMembershipStatus: (state) => {
			state.postMembershipStatus = null
		},
		resetGetInsuranceContractStatus: (state) => {
			state.getInsuranceContractStatus = null
		},
		resetGetInsuranceFacilityStatus: (state) => {
			state.getInsuranceFacilityStatus = null
		},
		resetPostCancelInsuranceStatus: (state) => {
			state.postCancelInsuranceStatus = null
		},
		resetPostInsuranceStatus: (state) => {
			state.postInsuranceStatus = null
		},
		resetGetContractOrganizationKitsStatus: (state) => {
			state.getContractOrganizationKitsStatus = null
		},
		resetGetFacilityOrganizationKitsStatus: (state) => {
			state.getFacilityOrganizationKitsStatus = null
		},
		resetGetUnitTagsStatus: (state) => {
			state.getUnitTagsStatus = null
		},
		resetPostUnitTagStatus: (state) => {
			state.postUnitTagStatus = null
		},
		resetPostUnitNoteStatus: (state) => {
			state.postUnitNoteStatus = null
		},
		resetGetFacilityUnitsStatus: (state) => {
			state.getFacilityUnitsStatus = null
		},
		resetGetFacilityPromotionsStatus: (state) => {
			state.getFacilityPromotionsStatus = null
		},
		resetUnitsAndPromotions: (state) => {
			state.facilityUnits = []
			state.facilityPromotions = []
		},
		resetPostGenerateBookingLinkStatus: (state) => {
			state.postGenerateBookingLinkStatus = null
		},
		resetBookingLink: (state) => {
			state.bookingLink = null
		},
		resetGetFacilityAuditStatus: (state) => {
			state.getFacilityAuditStatus = null
		},
		resetGetFacilityRunnersStatus: (state) => {
			state.getFacilityRunnersStatus = null
		},
		resetPutFacilityRunnerStatus: (state) => {
			state.putFacilityRunnerStatus = null
		},
		resetPostFacilityRunnerStatus: (state) => {
			state.postFacilityRunnerStatus = null
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(getPromotions.pending, (state, { payload }) => {
				state.getPromotionsStatus = 'PENDING'
			})
			.addCase(getPromotions.fulfilled, (state, { payload }) => {
				state.getPromotionsStatus = 'FULFILLED'
				state.promotions = payload
			})
			.addCase(getPromotions.rejected, (state, action) => {
				state.getPromotionsStatus = 'REJECTED'
			})
			.addCase(postPromotion.pending, (state, { payload }) => {
				state.postPromotionStatus = 'PENDING'
			})
			.addCase(postPromotion.fulfilled, (state, { payload }) => {
				state.postPromotionStatus = 'FULFILLED'
			})
			.addCase(postPromotion.rejected, (state, action) => {
				state.postPromotionStatus = 'REJECTED'
			})
			.addCase(putPromotion.pending, (state, { payload }) => {
				state.putPromotionStatus = 'PENDING'
			})
			.addCase(putPromotion.fulfilled, (state, { payload }) => {
				state.putPromotionStatus = 'FULFILLED'
			})
			.addCase(putPromotion.rejected, (state, action) => {
				state.putPromotionStatus = 'REJECTED'
			})
			.addCase(getDiscounts.pending, (state, { payload }) => {
				state.getDiscountsStatus = 'PENDING'
			})
			.addCase(getDiscounts.fulfilled, (state, { payload }) => {
				state.getDiscountsStatus = 'FULFILLED'
				state.discounts = payload
			})
			.addCase(getDiscounts.rejected, (state, action) => {
				state.getDiscountsStatus = 'REJECTED'
			})
			.addCase(postDiscount.pending, (state, { payload }) => {
				state.postDiscountStatus = 'PENDING'
			})
			.addCase(postDiscount.fulfilled, (state, { payload }) => {
				state.postDiscountStatus = 'FULFILLED'
			})
			.addCase(postDiscount.rejected, (state, action) => {
				state.postDiscountStatus = 'REJECTED'
			})
			.addCase(putDiscount.pending, (state, { payload }) => {
				state.putDiscountStatus = 'PENDING'
			})
			.addCase(putDiscount.fulfilled, (state, { payload }) => {
				state.putDiscountStatus = 'FULFILLED'
			})
			.addCase(putDiscount.rejected, (state, action) => {
				state.putDiscountStatus = 'REJECTED'
			})
			.addCase(getMemberContracts.pending, (state, { payload }) => {
				state.getMemberContractsStatus = 'PENDING'
			})
			.addCase(getMemberContracts.fulfilled, (state, { payload }) => {
				state.getMemberContractsStatus = 'FULFILLED'
				if (
					payload.status?.length === 1 &&
					payload.status[0] === CONTRACT.STATUS.PENDING_APPROVAL.id
				) {
					state.pendingMemberContracts = payload.data
				} else {
					state.memberContracts = payload.data
				}
			})
			.addCase(getMemberContracts.rejected, (state, action) => {
				state.getMemberContractsStatus = 'REJECTED'
			})
			.addCase(postApproveContract.pending, (state, { payload }) => {
				state.postApproveContractStatus = 'PENDING'
			})
			.addCase(postApproveContract.fulfilled, (state, { payload }) => {
				state.postApproveContractStatus = 'FULFILLED'
			})
			.addCase(postApproveContract.rejected, (state, action) => {
				state.postApproveContractStatus = 'REJECTED'
			})
			.addCase(getPerson.pending, (state, { payload }) => {
				state.getPersonStatus = 'PENDING'
			})
			.addCase(getPerson.fulfilled, (state, { payload }) => {
				state.getPersonStatus = 'FULFILLED'
				state.activePerson = payload.data
				state.getCaller = payload.caller
			})
			.addCase(getPerson.rejected, (state, action) => {
				state.getPersonStatus = 'REJECTED'
			})
			.addCase(getContract.pending, (state, { payload }) => {
				state.getContractStatus = 'PENDING'
			})
			.addCase(getContract.fulfilled, (state, { payload }) => {
				state.getContractStatus = 'FULFILLED'
				state.activeContract = payload
			})
			.addCase(getContract.rejected, (state, action) => {
				state.getContractStatus = 'REJECTED'
			})
			.addCase(putContract.pending, (state, { payload }) => {
				state.putContractStatus = 'PENDING'
				state.putContactError = {}
			})
			.addCase(putContract.fulfilled, (state, { payload }) => {
				state.putContractStatus = 'FULFILLED'
				state.putContactError = {}
			})
			.addCase(putContract.rejected, (state, action) => {
				state.putContractStatus = 'REJECTED'
				state.putContactError = action.error
			})
			.addCase(putContractEntryAccess.pending, (state, { payload }) => {
				state.putContractEntryAccessStatus = 'PENDING'
			})
			.addCase(putContractEntryAccess.fulfilled, (state, { payload }) => {
				state.putContractEntryAccessStatus = 'FULFILLED'
			})
			.addCase(putContractEntryAccess.rejected, (state, action) => {
				state.putContractEntryAccessStatus = 'REJECTED'
			})
			.addCase(putInvoice.pending, (state, { payload }) => {
				state.putInvoiceStatus = 'PENDING'
			})
			.addCase(putInvoice.fulfilled, (state, { payload }) => {
				state.putInvoiceStatus = 'FULFILLED'
			})
			.addCase(putInvoice.rejected, (state, action) => {
				state.putInvoiceStatus = 'REJECTED'
			})
			.addCase(getInvoicesByContractId.pending, (state, { payload }) => {
				state.getInvoicesByContractIdStatus = 'PENDING'
			})
			.addCase(getInvoicesByContractId.fulfilled, (state, { payload }) => {
				state.getInvoicesByContractIdStatus = 'FULFILLED'
				state.activeInvoiceList = payload
			})
			.addCase(getInvoicesByContractId.rejected, (state, action) => {
				state.getInvoicesByContractIdStatus = 'REJECTED'
			})
			.addCase(postChargeInvoice.pending, (state, { payload }) => {
				state.postChargeInvoiceStatus = 'PENDING'
			})
			.addCase(postChargeInvoice.fulfilled, (state, { payload }) => {
				state.postChargeInvoiceStatus = 'FULFILLED'
			})
			.addCase(postChargeInvoice.rejected, (state, action) => {
				state.postChargeInvoiceStatus = 'REJECTED'
			})
			.addCase(postUnlockFacilityV5.pending, (state, { payload }) => {
				state.postUnlockFacilityStatus = 'PENDING'
			})
			.addCase(postUnlockFacilityV5.fulfilled, (state, { payload }) => {
				state.postUnlockFacilityStatus = 'FULFILLED'
			})
			.addCase(postUnlockFacilityV5.rejected, (state, action) => {
				state.postUnlockFacilityStatus = 'REJECTED'
			})
			.addCase(postTerminateContract.pending, (state, { payload }) => {
				state.postTerminateContractStatus = 'PENDING'
			})
			.addCase(postTerminateContract.fulfilled, (state, { payload }) => {
				state.postTerminateContractStatus = 'FULFILLED'
			})
			.addCase(postTerminateContract.rejected, (state, action) => {
				state.postTerminateContractStatus = 'REJECTED'
			})
			.addCase(getCountries.pending, (state, { payload }) => {
				state.getCountriesStatus = 'PENDING'
			})
			.addCase(getCountries.fulfilled, (state, { payload }) => {
				state.getCountriesStatus = 'FULFILLED'
				state.countries = payload
			})
			.addCase(getCountries.rejected, (state, action) => {
				state.getCountriesStatus = 'REJECTED'
			})
			.addCase(getMetros.pending, (state) => {
				state.getMetrosStatus = 'PENDING'
			})
			.addCase(getMetros.fulfilled, (state, { payload }) => {
				state.getMetrosStatus = 'FULFILLED'
				state.metros = payload
			})
			.addCase(getMetros.rejected, (state) => {
				state.getMetrosStatus = 'REJECTED'
			})
			.addCase(postWebsitePhotos.pending, (state, { payload }) => {
				state.postWebsitePhotosStatus = 'PENDING'
			})
			.addCase(postWebsitePhotos.fulfilled, (state, { payload }) => {
				state.postWebsitePhotosStatus = 'FULFILLED'
			})
			.addCase(postWebsitePhotos.rejected, (state, action) => {
				state.postWebsitePhotosStatus = 'REJECTED'
			})
			.addCase(postMemberDashboardPhotos.pending, (state, { payload }) => {
				state.postMemberDashboardPhotosStatus = 'PENDING'
			})
			.addCase(postMemberDashboardPhotos.fulfilled, (state, { payload }) => {
				state.postMemberDashboardPhotosStatus = 'FULFILLED'
			})
			.addCase(postMemberDashboardPhotos.rejected, (state, action) => {
				state.postMemberDashboardPhotosStatus = 'REJECTED'
			})
			.addCase(getUpcomingLedgerItems.pending, (state) => {
				state.getUpcomingLedgerItemsStatus = 'PENDING'
			})
			.addCase(getUpcomingLedgerItems.fulfilled, (state, { payload }) => {
				state.getUpcomingLedgerItemsStatus = 'FULFILLED'
				state.upcomingMemberTransactions = payload
			})
			.addCase(getUpcomingLedgerItems.rejected, (state) => {
				state.getUpcomingLedgerItemsStatus = 'REJECTED'
			})
			.addCase(putLedgerItem.pending, (state) => {
				state.putLedgerItemStatus = 'PENDING'
			})
			.addCase(putLedgerItem.fulfilled, (state) => {
				state.putLedgerItemStatus = 'FULFILLED'
			})
			.addCase(putLedgerItem.rejected, (state) => {
				state.putLedgerItemStatus = 'REJECTED'
			})
			.addCase(getCompletedInvoices.pending, (state) => {
				state.getCompletedInvoicesStatus = 'PENDING'
			})
			.addCase(getCompletedInvoices.fulfilled, (state, { payload }) => {
				state.getCompletedInvoicesStatus = 'FULFILLED'
				state.completedMemberTransactions = payload
			})
			.addCase(getCompletedInvoices.rejected, (state) => {
				state.getCompletedInvoicesStatus = 'REJECTED'
			})
			.addCase(postCancelInvoice.pending, (state) => {
				state.postCancelInvoiceStatus = 'PENDING'
			})
			.addCase(postCancelInvoice.fulfilled, (state) => {
				state.postCancelInvoiceStatus = 'FULFILLED'
			})
			.addCase(postCancelInvoice.rejected, (state) => {
				state.postCancelInvoiceStatus = 'REJECTED'
			})
			.addCase(postRefundInvoice.pending, (state) => {
				state.postRefundInvoiceStatus = 'PENDING'
			})
			.addCase(postRefundInvoice.fulfilled, (state) => {
				state.postRefundInvoiceStatus = 'FULFILLED'
			})
			.addCase(postRefundInvoice.rejected, (state) => {
				state.postRefundInvoiceStatus = 'REJECTED'
			})
			.addCase(postContractBalance.pending, (state) => {
				state.postContractBalanceStatus = 'PENDING'
			})
			.addCase(postContractBalance.fulfilled, (state) => {
				state.postContractBalanceStatus = 'FULFILLED'
			})
			.addCase(postContractBalance.rejected, (state) => {
				state.postContractBalanceStatus = 'REJECTED'
			})
			.addCase(postMetro.pending, (state) => {
				state.postMetroStatus = 'PENDING'
			})
			.addCase(postMetro.fulfilled, (state, { payload }) => {
				state.postMetroStatus = 'FULFILLED'
			})
			.addCase(postMetro.rejected, (state) => {
				state.postMetroStatus = 'REJECTED'
			})
			.addCase(postCity.pending, (state) => {
				state.postCityStatus = 'PENDING'
			})
			.addCase(postCity.fulfilled, (state) => {
				state.postCityStatus = 'FULFILLED'
			})
			.addCase(postCity.rejected, (state) => {
				state.postCityStatus = 'REJECTED'
			})
			.addCase(postCalculateNextPayment.pending, (state) => {
				state.postCalculateNextPaymentStatus = 'PENDING'
			})
			.addCase(postCalculateNextPayment.fulfilled, (state, { payload }) => {
				state.postCalculateNextPaymentStatus = 'FULFILLED'
				state.activePersonNextPayment = payload
			})
			.addCase(postCalculateNextPayment.rejected, (state) => {
				state.postCalculateNextPaymentStatus = 'REJECTED'
			})
			.addCase(postUnits.pending, (state) => {
				state.postUnitsStatus = 'PENDING'
			})
			.addCase(postUnits.fulfilled, (state) => {
				state.postUnitsStatus = 'FULFILLED'
			})
			.addCase(postUnits.rejected, (state) => {
				state.postUnitsStatus = 'REJECTED'
			})
			.addCase(getUnits.pending, (state) => {
				state.getUnitsStatus = 'PENDING'
			})
			.addCase(getUnits.fulfilled, (state, { payload }) => {
				state.getUnitsStatus = 'FULFILLED'
				state.units = payload
			})
			.addCase(getUnits.rejected, (state) => {
				state.getUnitsStatus = 'REJECTED'
			})
			.addCase(getTags.pending, (state) => {
				state.getTagsStatus = 'PENDING'
			})
			.addCase(getTags.fulfilled, (state, { payload }) => {
				state.getTagsStatus = 'FULFILLED'
				state.tags = payload
			})
			.addCase(getTags.rejected, (state) => {
				state.getTagsStatus = 'REJECTED'
			})
			.addCase(postTag.pending, (state) => {
				state.postTagStatus = 'PENDING'
			})
			.addCase(postTag.fulfilled, (state) => {
				state.postTagStatus = 'FULFILLED'
			})
			.addCase(postTag.rejected, (state) => {
				state.postTagStatus = 'REJECTED'
			})
			.addCase(putTag.pending, (state) => {
				state.putTagStatus = 'PENDING'
			})
			.addCase(putTag.fulfilled, (state) => {
				state.putTagStatus = 'FULFILLED'
			})
			.addCase(putTag.rejected, (state) => {
				state.putTagStatus = 'REJECTED'
			})
			.addCase(getPersonByPhoneNbr.pending, (state) => {
				state.getPersonByPhoneNbrStatus = 'PENDING'
			})
			.addCase(getPersonByPhoneNbr.fulfilled, (state, { payload }) => {
				state.getPersonByPhoneNbrStatus = 'FULFILLED'
				state.getPersonByPhoneErrorCode = null
				state.personByPhone = payload
			})
			.addCase(getPersonByPhoneNbr.rejected, (state, action) => {
				state.getPersonByPhoneNbrStatus = 'REJECTED'
				state.getPersonByPhoneErrorCode =
					JSON.parse(action.error.message)?.code || null
				state.personByPhone = {}
			})
			.addCase(postContractSecondaryAccess.pending, (state) => {
				state.postContractSecondaryAccessStatus = 'PENDING'
			})
			.addCase(postContractSecondaryAccess.fulfilled, (state) => {
				state.postContractSecondaryAccessStatus = 'FULFILLED'
			})
			.addCase(postContractSecondaryAccess.rejected, (state) => {
				state.postContractSecondaryAccessStatus = 'REJECTED'
			})
			.addCase(getSecondaryAccess.pending, (state) => {
				state.getSecondaryAccessStatus = 'PENDING'
			})
			.addCase(getSecondaryAccess.fulfilled, (state, { payload }) => {
				state.getSecondaryAccessStatus = 'FULFILLED'
				state.secondaryAccess = payload
			})
			.addCase(getSecondaryAccess.rejected, (state) => {
				state.getSecondaryAccessStatus = 'REJECTED'
			})
			.addCase(putContractSecondaryAccess.pending, (state) => {
				state.putContractSecondaryAccessStatus = 'PENDING'
			})
			.addCase(putContractSecondaryAccess.fulfilled, (state) => {
				state.putContractSecondaryAccessStatus = 'FULFILLED'
			})
			.addCase(putContractSecondaryAccess.rejected, (state) => {
				state.putContractSecondaryAccessStatus = 'REJECTED'
			})
			.addCase(getMembershipContract.pending, (state) => {
				state.getMembershipContractStatus = 'PENDING'
			})
			.addCase(getMembershipContract.fulfilled, (state, { payload }) => {
				state.getMembershipContractStatus = 'FULFILLED'
				state.membership = payload
			})
			.addCase(getMembershipContract.rejected, (state) => {
				state.getMembershipContractStatus = 'REJECTED'
			})
			.addCase(postMembership.pending, (state) => {
				state.postMembershipStatus = 'PENDING'
			})
			.addCase(postMembership.fulfilled, (state) => {
				state.postMembershipStatus = 'FULFILLED'
			})
			.addCase(postMembership.rejected, (state) => {
				state.postMembershipStatus = 'REJECTED'
			})
			.addCase(getInsuranceContract.pending, (state) => {
				state.getInsuranceContractStatus = 'PENDING'
			})
			.addCase(getInsuranceContract.fulfilled, (state, { payload }) => {
				state.getInsuranceContractStatus = 'FULFILLED'
				state.insurance = payload
			})
			.addCase(getInsuranceContract.rejected, (state) => {
				state.getInsuranceContractStatus = 'REJECTED'
			})
			.addCase(getInsuranceFacility.pending, (state) => {
				state.getInsuranceFacilityStatus = 'PENDING'
			})
			.addCase(getInsuranceFacility.fulfilled, (state, { payload }) => {
				state.getInsuranceFacilityStatus = 'FULFILLED'
				state.insuranceFacility = payload
			})
			.addCase(getInsuranceFacility.rejected, (state) => {
				state.getInsuranceFacilityStatus = 'REJECTED'
			})
			.addCase(postCancelInsurance.pending, (state) => {
				state.postCancelInsuranceStatus = 'PENDING'
			})
			.addCase(postCancelInsurance.fulfilled, (state) => {
				state.postCancelInsuranceStatus = 'FULFILLED'
			})
			.addCase(postCancelInsurance.rejected, (state) => {
				state.postCancelInsuranceStatus = 'REJECTED'
			})
			.addCase(postInsurance.pending, (state) => {
				state.postInsuranceStatus = 'PENDING'
			})
			.addCase(postInsurance.fulfilled, (state) => {
				state.postInsuranceStatus = 'FULFILLED'
			})
			.addCase(postInsurance.rejected, (state) => {
				state.postInsuranceStatus = 'REJECTED'
			})
			.addCase(getContractOrganizationKits.pending, (state) => {
				state.getContractOrganizationKitsStatus = 'PENDING'
			})
			.addCase(getContractOrganizationKits.fulfilled, (state, { payload }) => {
				state.getContractOrganizationKitsStatus = 'FULFILLED'
				state.contractOrganizationKits = payload
			})
			.addCase(getContractOrganizationKits.rejected, (state) => {
				state.getContractOrganizationKitsStatus = 'REJECTED'
			})
			.addCase(getFacilityOrganizationKits.pending, (state) => {
				state.getFacilityOrganizationKitsStatus = 'PENDING'
			})
			.addCase(getFacilityOrganizationKits.fulfilled, (state, { payload }) => {
				state.getFacilityOrganizationKitsStatus = 'FULFILLED'
				state.facilityOrganizationKits = payload
			})
			.addCase(getFacilityOrganizationKits.rejected, (state) => {
				state.getFacilityOrganizationKitsStatus = 'REJECTED'
			})
			.addCase(getUnitTags.pending, (state) => {
				state.getUnitTagsStatus = 'PENDING'
			})
			.addCase(getUnitTags.fulfilled, (state, { payload }) => {
				state.getUnitTagsStatus = 'FULFILLED'
				state.unitTags = payload
			})
			.addCase(getUnitTags.rejected, (state) => {
				state.getUnitTagsStatus = 'REJECTED'
			})
			.addCase(postUnitTag.pending, (state) => {
				state.postUnitTagStatus = 'PENDING'
			})
			.addCase(postUnitTag.fulfilled, (state) => {
				state.postUnitTagStatus = 'FULFILLED'
			})
			.addCase(postUnitTag.rejected, (state) => {
				state.postUnitTagStatus = 'REJECTED'
			})
			.addCase(postUnitNote.pending, (state) => {
				state.postUnitNoteStatus = 'PENDING'
			})
			.addCase(postUnitNote.fulfilled, (state) => {
				state.postUnitNoteStatus = 'FULFILLED'
			})
			.addCase(postUnitNote.rejected, (state) => {
				state.postUnitNoteStatus = 'REJECTED'
			})
			.addCase(getFacilityUnits.pending, (state) => {
				state.getFacilityUnitsStatus = 'PENDING'
			})
			.addCase(getFacilityUnits.fulfilled, (state, { payload }) => {
				state.getFacilityUnitsStatus = 'FULFILLED'
				state.facilityUnits = payload
			})
			.addCase(getFacilityUnits.rejected, (state) => {
				state.getFacilityUnitsStatus = 'REJECTED'
			})
			.addCase(getFacilityPromotions.pending, (state) => {
				state.getFacilityPromotionsStatus = 'PENDING'
			})
			.addCase(getFacilityPromotions.fulfilled, (state, { payload }) => {
				state.getFacilityPromotionsStatus = 'FULFILLED'
				state.facilityPromotions = payload
			})
			.addCase(getFacilityPromotions.rejected, (state) => {
				state.getFacilityPromotionsStatus = 'REJECTED'
			})
			.addCase(postGenerateBookingLink.pending, (state) => {
				state.postGenerateBookingLinkStatus = 'PENDING'
			})
			.addCase(postGenerateBookingLink.fulfilled, (state, { payload }) => {
				state.postGenerateBookingLinkStatus = 'FULFILLED'
				state.bookingLink = payload.booking_url
			})
			.addCase(postGenerateBookingLink.rejected, (state) => {
				state.postGenerateBookingLinkStatus = 'REJECTED'
			})
			.addCase(getFacilityAudit.pending, (state) => {
				state.getFacilityAuditStatus = 'PENDING'
			})
			.addCase(getFacilityAudit.fulfilled, (state, { payload }) => {
				state.getFacilityAuditStatus = 'FULFILLED'
				state.audit = payload
			})
			.addCase(getFacilityAudit.rejected, (state) => {
				state.getFacilityAuditStatus = 'REJECTED'
			})
			.addCase(getFacilityRunners.pending, (state) => {
				state.getFacilityRunnersStatus = 'PENDING'
			})
			.addCase(getFacilityRunners.fulfilled, (state, { payload }) => {
				state.getFacilityRunnersStatus = 'FULFILLED'
				state.facilityRunners = payload
			})
			.addCase(getFacilityRunners.rejected, (state) => {
				state.getFacilityRunnersStatus = 'REJECTED'
			})
			.addCase(putFacilityRunner.pending, (state) => {
				state.putFacilityRunnerStatus = 'PENDING'
			})
			.addCase(putFacilityRunner.fulfilled, (state) => {
				state.putFacilityRunnerStatus = 'FULFILLED'
			})
			.addCase(putFacilityRunner.rejected, (state) => {
				state.putFacilityRunnerStatus = 'REJECTED'
			})
			.addCase(postFacilityRunner.pending, (state) => {
				state.postFacilityRunnerStatus = 'PENDING'
			})
			.addCase(postFacilityRunner.fulfilled, (state) => {
				state.postFacilityRunnerStatus = 'FULFILLED'
			})
			.addCase(postFacilityRunner.rejected, (state) => {
				state.postFacilityRunnerStatus = 'REJECTED'
			})
	}
})

const { actions, reducer } = adminDashboardSlice
export const {
	resetGetPromotionsStatus,
	resetPostPromotionStatus,
	resetPutPromotionStatus,
	resetGetDiscountsStatus,
	resetPostDiscountStatus,
	resetPutDiscountStatus,
	resetGetMemberContractsStatus,
	resetGetContractStatus,
	resetPutContractStatus,
	resetPostApproveContractStatus,
	resetGetPersonStatus,
	resetPutContractEntryAccessStatus,
	resetActivePerson,
	resetActiveContract,
	setActiveContract,
	resetActiveInvoiceList,
	resetGetInvoicesByContractIdStatus,
	resetPutInvoiceStatus,
	resetPostChargeInvoiceStatus,
	resetPostUnlockFacilityStatus,
	resetPostTerminateContractStatus,
	resetGetCountriesStatus,
	resetPostWebsitePhotosStatus,
	resetPostMemberDashboardPhotosStatus,
	resetGetUpcomingLedgerItemsStatus,
	resetPutLedgerItemStatus,
	resetGetCompletedInvoicesStatus,
	resetPostCancelInvoiceStatus,
	resetPostRefundInvoiceStatus,
	resetPostContractBalanceStatus,
	resetGetMetrosStatus,
	resetPostMetroStatus,
	resetPostCityStatus,
	resetPostCalculateNextPaymentStatus,
	resetPostUnitsStatus,
	resetGetUnitsStatus,
	resetGetTagsStatus,
	resetPostTagStatus,
	resetPutTagStatus,
	resetGetPersonByPhoneErrorCode,
	resetGetPersonByPhoneNbrStatus,
	resetGetSecondaryAccessStatus,
	resetpostContractSecondaryAccessStatus,
	resetPutContractSecondaryAccessStatus,
	resetGetMembershipContractStatus,
	resetPostMembershipStatus,
	resetGetInsuranceContractStatus,
	resetGetInsuranceFacilityStatus,
	resetPostCancelInsuranceStatus,
	resetPostInsuranceStatus,
	resetGetContractOrganizationKitsStatus,
	resetGetFacilityOrganizationKitsStatus,
	resetGetUnitTagsStatus,
	resetPostUnitTagStatus,
	resetPostUnitNoteStatus,
	resetGetFacilityUnitsStatus,
	resetGetFacilityPromotionsStatus,
	resetUnitsAndPromotions,
	resetPostGenerateBookingLinkStatus,
	resetBookingLink,
	resetGetFacilityAuditStatus,
	resetGetFacilityRunnersStatus,
	resetPutFacilityRunnerStatus,
	resetPostFacilityRunnerStatus
} = actions
export default reducer
